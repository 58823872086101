export default class GenericModel {

    static get fields() {
        return this.fields;
    }

    static presenter(row, fieldName) {
        const fieldExists = this.fields.some(field => field.name === fieldName);
        if (!fieldExists) {
            throw new Error(`${fieldName} not found`);
        }

        const field = this.fields.find((el) => el.name === fieldName);
        let record = field?.computeFn ? row : row[field.name];

        //can be used if value if found in an object of objects
        // if (fieldName.indexOf('.') !== -1){
        //     const callbackFn = (acc, current) => {
        //         let value = [];
        //         if (Array.isArray(acc)) {
        //             acc.forEach((el) => {
        //                 if (el[current]) {
        //                     value.push(el[current]);
        //                 }
        //             });

        //             return value.length === 1 ? value[0] : value.join(', ');
        //         }

        //         return acc && acc[current] ? acc[current] : null;
        //     }

        //     const keyDepthPath = fieldName.split('.');
        //     record = keyDepthPath.reduce(callbackFn, row);
        // }

        return field.forPresenter(record);
    }

}
